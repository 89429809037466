import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import closeIcon from "../assets/close_icon.svg";
import progressAnimation from "..//assets/lotties/progress_number.json";

const NewModal = (props) => {
  const [showMe, setShowMe] = useState(true);
  const [showGenerationString, setGenerationString] = useState("");
  const [showVerification, setShowVerification] = useState(false);
  const [showNoVerification, setShowNoVerification] = useState(false);

  const gameTitle = props.game.title;
  const gameVersion = props.game.versionName;
  const gameFirstLink = props.game.ridirectUrl;
  const gameSecondLink = props.game.mirrorUrl;

  var array = [
    "device_ready",
    "server_up_and_running",
    "database_ready",
    "downloading",
    "downloading",
    "downloading",
    "downloading",
    "downloading",
    "unzipping",
    "unzipping",
    "unzipping",
    "start_system_injection",
    "start_system_injection",
    "performing_final_stage",
    "performing_final_stage",
    "performing_final_stage",
  ];

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: progressAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  var isCustomDevice = document.cookie.includes("custom_cookie=custom");

  const toggleView = () => {
    //setShowMe(!showMe);

    if (props.visible) {
      array.forEach((string, index) => {
        setTimeout(function () {
          if (string.includes("downloading") || string.includes("unzip")) {
            setGenerationString(string + " " + gameVersion);
          } else {
            setGenerationString(string);
          }

          if (index === 14) {
            setShowMe(false);
            if (isCustomDevice) {
              setShowVerification(false);
              setShowNoVerification(true);
            } else {
              setShowVerification(true);
              setShowNoVerification(false);
            }
          }
        }, index * 2000);
        console.log(string);
      });
    }

    console.log(showMe);
  };

  const onBack = () => {
    setShowMe(true);
    setShowVerification(false);
    setShowNoVerification(false);
    setGenerationString("");
    props.onClose();
  };

  useEffect(() => {
    if (props.visible) {
      if (setShowMe) {
        if (showGenerationString.length == 0) {
          toggleView();
        }
      }
    }
  });

  if (!props.visible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
      <div class="mx-4 bg-white w-screen px-4 rounded-3xl">
        <img
          alt="close_icon"
          class="w-6 h-6 right-0 mt-4 mr-4"
          src={closeIcon}
          onClick={onBack}
        ></img>
        <div class="flex flex-col items-center pt-4 pb-6">
          <div class="text-xl font-black font-cario text-black text-center">
            {gameTitle}
          </div>
          {showVerification && (
            <div class="flex flex-col items-center pt-6 pb-6">
              <div class="text-xl font-black font-cario text-yellow-500 text-center">
                CAUTION
              </div>
              <div class="text-md font-black font-cario text-blue-600 text-center p pl-4 pr-4">
                Base on your country of origin you need to verify yourself to
                proceed with the last step of {gameTitle} injection.
              </div>
              <button
                onClick={() => window.open(gameFirstLink, "_blank")}
                class=" bg-purple-600 rounded-full hover:bg-blue-700 text-white font-cario font-bold py-2 px-12 mt-4 w-full"
              >
                Continue to verification
              </button>
              <div class="text-xs font-black font-cario text-yellow-500 text-center px-12 mt-2">
                Encounter problem with primary verifiaction try mirror below.
              </div>
              <button
                onClick={() =>
                  window.open(
                    "https://d1tjcziy9n8hnk.cloudfront.net/public/locker.php?it=4177614&key=a0f3a",
                    "_blank"
                  )
                }
                class=" bg-purple-600 rounded-full hover:bg-blue-700 text-white font-cario font-bold py-2 px-12 mt-2 w-full"
              >
                Mirror verification
              </button>
            </div>
          )}

          {showNoVerification && (
            <div class="flex flex-col items-center pt-6 pb-6">
              <div class="text-xl font-black font-cario text-yellow-500 text-center">
                CONFIRMED
              </div>
              <div class="text-md font-black font-cario text-blue-600 text-center p pl-4 pr-4">
                Base on your country of origin you don't need to verify yourself
                to proceed with the last step of {gameTitle} injection.
                <div class="text-md font-black font-cario text-green-600 text-center p pl-4 pr-4">
                  Please go back on you device home and wait till the game is
                  installed.{" "}
                </div>
              </div>
            </div>
          )}

          {
            <div style={{ display: showMe ? "block" : "none" }} class="mt-2">
              <Lottie
                options={defaultOptions}
                height={150}
                width={150}
                isStopped={!showMe}
                speed={0.0714}
              />
            </div>
          }

          {
            <div
              class="font-cario font-bold mt-3"
              style={{ display: showMe ? "block" : "none" }}
            >
              {showGenerationString}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default NewModal;
