import "./App.css";
import Loading from "./Loading";
import Login from "./Login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AddGamesAdmin from "./AddGamesAdmin";
import NewHome from "./NewHome";
import NewDetailsComponent from "./components/NewDetailsComponent";

function App() {
  return (
    <Router>
      <div className="bg-red">
        <Routes>
          <Route path="/" element={<Loading />} />
          <Route path="/home" element={<NewHome />} />
          <Route path="/home/details" element={<NewDetailsComponent />} />
          <Route path="/login" element={<Login />} />
          <Route path="/manage" element={<AddGamesAdmin />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
