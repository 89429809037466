import loading from "./assets/lotties/loading.json";
import Lottie from "react-lottie";
import { useEffect } from "react";

import axios from "axios";
import { GET_GAMES_URL } from "./Constants";
import { useNavigate } from "react-router-dom";

const Loading = () => {
  let navigation = useNavigate();

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  let firstCall = false;
  useEffect(() => {
    if (!firstCall) {
      firstCall = true;
      axios
        .post(GET_GAMES_URL())
        .then((result) => {
          if (result.data.length > 0) {
            console.log(result.data);
            firstCall = true;

            let path = "home";
            navigation(path, { state: { games: result.data } });
          }

          console.log(result.code);
        })
        .catch((error) => {
          alert(JSON.stringify(error));
        });
    }
  });

  return (
    <div className="h-[100vh] w-full bg-black bg-cyan-50 flex">
      <div className="m-auto">
        <Lottie
          options={loadingOptions}
          height={150}
          width={150}
          speed={0.0714}
        />
      </div>
    </div>
  );
};

export default Loading;
