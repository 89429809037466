import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import {
  ADD_GAME_ENPOINT,
  GET_ADD_GAME_URL,
  GET_IMG_URL,
  IMAGE_UPLOAD_ENDPOINT,
} from "./Constants";

const AddGamesAdmin = (props) => {
  const location = useLocation();

  const [imageFile, setImageFile] = useState(null);
  const [imageName, setImageName] = useState("");

  if (!location.state.isAuth) return null;

  const handleSubmit = (event) => {
    console.log(event);
    event.preventDefault();

    var {
      title,
      description,
      rating,
      review,
      download,
      author,
      isIos,
      isAndroid,
      isLatest,
      size,
      version,
      url,
    } = document.forms[0];

    const sendData = {
      title: title.value,
      description: description.value,
      iconImage: imageName,
      rating: rating.value,
      review: review.value,
      download: download.value,
      author: author.value,
      isIos: isIos.value,
      isAndroid: isAndroid.value,
      isLatest: isLatest.value,
      size: size.value,
      version: version.value,
      url: url.value,
    };

    if (imageName.length == 0) {
      alert("Image not upload");
      return;
    }

    axios.post(GET_ADD_GAME_URL(), sendData).then((result) => {
      console.log(result);
      if (result.data == true) {
        alert("Success adding data");
      } else {
        alert("Error adding data");
      }
    });
  };

  const handleFile = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    setImageFile(file);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    console.log(imageFile);

    const formData = new FormData();
    formData.append("iconImg", imageFile);

    axios
      .post(GET_IMG_URL(), formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((result) => {
        if (result.data.length > 0) {
          alert("Image upload");
          setImageName(result.data);
        } else {
          alert("Error uploading image");
        }
      });
  };

  return (
    <div>
      <div>
        <input type="file" name="file" onChange={(e) => handleFile(e)} />
        <button
          onClick={(e) => handleUpload(e)}
          className="border-2 border-black"
        >
          Upload
        </button>
      </div>
      <div className="form mt-4" onSubmit={handleSubmit}>
        <form id="outer">
          <div className="input-container  ">
            <label>Title </label>
            <input
              type="text"
              name="title"
              className="border-2 border-rose-600"
              required
            />
          </div>
          <div className="input-container mt-4">
            <label>Description</label>
            <input
              type="text"
              name="description"
              className="border-2 border-rose-600"
              required
            />
          </div>
          <div className="input-container mt-4">
            <label>Rating</label>
            <input
              type="number"
              name="rating"
              step="any"
              placeholder="2.2"
              className="border-2 border-rose-600"
              required
            />
          </div>
          <div className="input-container mt-4">
            <label>Reviews</label>
            <input
              type="text"
              name="review"
              placeholder="2k+"
              className="border-2 border-rose-600"
              required
            />
          </div>
          <div className="input-container mt-4">
            <label>Download</label>
            <input
              type="text"
              name="download"
              placeholder="2k+"
              className="border-2 border-rose-600"
              required
            />
          </div>
          <div className="input-container mt-4">
            <label>Author</label>
            <input
              type="text"
              name="author"
              className="border-2 border-rose-600"
              required
            />
          </div>
          <div className="input-container mt-4">
            <label>IsForIos</label>
            <input
              type="number"
              name="isIos"
              className="border-2 border-rose-600"
              required
            />
          </div>
          <div className="input-container mt-4">
            <label>IsForAndroid</label>
            <input
              type="number"
              name="isAndroid"
              className="border-2 border-rose-600"
              required
            />
          </div>
          <div className="input-container mt-4">
            <label>isLatestGame</label>
            <input
              type="number"
              name="isLatest"
              className="border-2 border-rose-600"
              required
            />
          </div>
          <div className="input-container mt-4">
            <label>Size</label>
            <input
              type="text"
              name="size"
              placeholder="20"
              className="border-2 border-rose-600"
              required
            />
          </div>
          <div className="input-container mt-4">
            <label>Version</label>
            <input
              type="text"
              name="version"
              className="border-2 border-rose-600"
              required
            />
          </div>
          <div className="input-container mt-4">
            <label>URL</label>
            <input
              type="text"
              name="url"
              className="border-2 border-rose-600"
              required
            />
          </div>
          <div className="button-container mt-4 ">
            <input type="submit" className=" border-2 border-black" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddGamesAdmin;
