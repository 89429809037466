import { LazyLoadImage } from "react-lazy-load-image-component";

const NewCardComponent = (props) => {
  const titleCorrection = () => {
    if (props.game.title.length > 33) {
      let s = props.game.title.substring(0, 33);
      return s + "...";
    } else {
      return props.game.title;
    }
  };

  return (
    <div
      onClick={() => props.callback(props.game)}
      className="flex flex-col  rounded-xl w-40 bg-white shadow-md mr-4 h-56 justify-around"
    >
      <LazyLoadImage 
        className="w-full h-28 bg-black  rounded-t-xl"
        src={"https://mobgames.online/images/" + props.game.iconPath}
        alt={props.game.title}
      />
      <div className="flex flex-col">
        <h1 className=" mx-2 text-l font-bold break-words">
          {titleCorrection()}
        </h1>
        <h4 className="text-xs mx-2">{props.game.author}</h4>
      </div>

      <div className="flex flex-row mt-2 mx-2 mb-2 justify-between">
        <div className="flex flex-row">
          <svg
            aria-hidden="true"
            class="w-5 h-5 text-yellow-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
          </svg>
          <p class="ml-2 text-sm font-bold text-gray-900">
            {props.game.rating}
          </p>
        </div>
        <div className="flex flex-row">
          <p className="text-sm font-bold  text-yellow-400">
            {props.game.size}
          </p>
          <p className="text-xs mt-0.5 font-bold text-gray-900 ml-1">MB</p>
        </div>
      </div>
    </div>
  );
};

export default NewCardComponent;
